import { ApiV2DetailV5 } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { DetailV5Action, setDetailData } from './store/actions';
import detailV5Reducer from './store/reducer';
import { DetailContextState } from './types';

type DetailContextProps = {
  state: ApiV2DetailV5;
  dispatch: React.Dispatch<DetailV5Action>;
  isDetail: boolean;
};

const DetailContext = createContext({ isDetail: false } as DetailContextProps);
DetailContext.displayName = 'DetailContext';

type DetailProviderProps = {
  children: React.ReactNode;
  initData: DetailContextState;
};

/**
 * DetailProvider
 * @param {children} reactcomponent
 * @description Provider for detail page.
 * @returns provider
 */
function DetailProvider({
  children,
  initData,
}: DetailProviderProps): JSX.Element | null {
  // initData is necessary for noJS detailPage
  const [state, dispatch] = useReducer(detailV5Reducer, initData);

  const value: DetailContextProps = useMemo(
    () => ({ state, dispatch, isDetail: true }),
    [state, dispatch]
  );

  useEffect(() => {
    dispatch(setDetailData(initData));
  }, [initData, dispatch]);

  // render only if detail data is setted with initData
  return state.actionLayout ? (
    <DetailContext.Provider value={value}>{children}</DetailContext.Provider>
  ) : null;
}

/**
 * useDetailContext
 * @description wrapper for useContext
 * @returns {object} context state
 */
const useDetailContext = (): DetailContextState & {
  callbackState?: string;
} => {
  const context = useContext(DetailContext);

  if (context === undefined) {
    throw new Error('UseDetailContext must be used within a DetailProvider');
  }

  return context?.state;
};

export function useIsFromDetail(): boolean {
  const context = useContext(DetailContext);

  return context.isDetail;
}

const useDetailDispatch = (): React.Dispatch<DetailV5Action> =>
  useContext(DetailContext)?.dispatch;

export { DetailProvider, useDetailContext, useDetailDispatch };
