import { createContext, ReactNode } from 'react';

export const LandingContext = createContext<boolean>(false);

export function LandingContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <LandingContext.Provider value={true}>{children}</LandingContext.Provider>
  );
}
