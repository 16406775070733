import { createContext, ReactNode } from 'react';

export const GabaritListContext = createContext<boolean>(false);

export function GabaritListContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <GabaritListContext.Provider value={true}>
      {children}
    </GabaritListContext.Provider>
  );
}
