import {
  LINEFEED_CARRIAGE_RETURN_REGEX,
  QUOTES_REGEX,
} from '../regex/constants';

/**
 * String Helper
 * --------------------
 *
 * Clean a **string** by
 * - escaping the **quotes** characters
 * - removing the **linefeed** and **carriage return**
 *
 * @param string The **string** to parse
 * @return Return the **cleaned string**
 *
 * @category String
 * @example
 * getCleanedString('text with \n "quote"') // 'text with  \"quote\"'
 */
export const getCleanedString = (string: string): string => {
  const stringWithEscapedQuotes = string.replace(QUOTES_REGEX, '\\"');
  const stringWithoutLinefeedAndCarriageReturn =
    stringWithEscapedQuotes.replace(LINEFEED_CARRIAGE_RETURN_REGEX, '');

  return stringWithoutLinefeedAndCarriageReturn;
};
