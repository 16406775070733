import { Template } from '@canalplus/sdk-hodor';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import {
  getAlternateLinks,
  getDocumentHeaderMetasType,
} from '../../helpers/metaTags/metaTags-helper';
import {
  deleteQueryString,
  getProductionHostname,
  getUrlWithoutDefaultLang,
} from '../../helpers/url/url-helper';
import {
  hostnameSelector,
  offerLocationSelector,
} from '../../store/slices/application-selectors';
import { immersiveOrPagePathnameSelector } from '../../store/slices/immersive-selectors';
import { pathnameSelector } from '../../store/slices/routing-selectors';
import { AllQueryHandlerResult } from '../MetaTags/getFormattedMeta';
import { useIsFrom } from '../Page/useIsFrom';

type AlternateLinksTagsProps = {
  data: AllQueryHandlerResult;
  metaCanonical?: string;
};

/**
 * Handle alternate link meta tag
 * values of alternate links are determined by currentPage.alternate property from hodor response
 */
export function AlternateLinksTags({
  data,
  metaCanonical,
}: AlternateLinksTagsProps): JSX.Element | null {
  const offerLocation = useSelector(offerLocationSelector);
  const currentUrl = useSelector(hostnameSelector);
  const pathname = useSelector(pathnameSelector) || '';
  const urlPage = useSelector(immersiveOrPagePathnameSelector);
  const isFromDetail = useIsFrom(Template.DetailPage);

  const currentPage = data.pages?.[0]?.currentPage || data.currentPage;
  const alternateLinks = currentPage?.alternate;

  const documentHeader = getDocumentHeaderMetasType(offerLocation);

  if (!alternateLinks) {
    return null;
  }

  const defaultHostname =
    documentHeader?.app.head.defaultHostname ?? currentUrl;
  /**
   * Force mycanal in canonical url for all overlays if template is DETAIL_PAGE or DETAIL_SEASON
   */
  const hostname = isFromDetail ? defaultHostname : currentUrl;
  const canonicalUrl = `https://${getProductionHostname(hostname)}${
    metaCanonical || getUrlWithoutDefaultLang(deleteQueryString(urlPage))
  }`;

  const links = [
    { rel: 'canonical', href: canonicalUrl },
    // add alternate links
    ...getAlternateLinks(alternateLinks, hostname, pathname, offerLocation),
  ];

  return <Helmet link={links} />;
}
