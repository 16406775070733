import classNames from 'classnames';
import styles from './Heading.module.css';
import type { HeadingProps } from './HeadingTypes';

/**
 * HeadingPlaceholder
 *
 * used to display a placeholder when Heading has no content
 *
 * @param {bool} isBig toggles bigger font
 * @returns {node}
 */

export function HeadingPlaceholder({
  isBig = false,
}: HeadingProps): JSX.Element {
  return (
    <div
      className={classNames(styles.heading, styles['heading--loader'], {
        [styles['heading--big']]: isBig,
      })}
    />
  );
}
