import classNames from 'classnames';
import styles from './Heading.module.css';
import type { HeadingHTMLTags, HeadingProps } from './HeadingTypes';
import { HeadingLevels } from './HeadingTypes';

const DEFAULT_LEVEL = HeadingLevels.LEVEL_2;

const isHeadingLevel = (level: number): level is HeadingLevels => {
  const availableLevels = Object.keys(HeadingLevels).map(
    (k) => HeadingLevels[k] as number
  );
  return availableLevels.includes(level);
};

export function HeadingContent({
  level = HeadingLevels.LEVEL_2,
  text,
  isBig,
  isFromDetail = false,
  isFromShowcase = false,
}: HeadingProps): JSX.Element {
  let headingLevel = isHeadingLevel(level) ? level : DEFAULT_LEVEL;
  if (isBig) {
    headingLevel = HeadingLevels.LEVEL_1;
  }

  const TitleTag = `h${headingLevel}` as HeadingHTMLTags;
  return (
    <TitleTag
      data-testid="title-tag"
      className={classNames(styles.heading, {
        [styles['heading--detailV5']]: isFromDetail,
        [styles['heading--showcase']]: isFromShowcase,
        [styles['heading--is-big']]: isBig,
        [styles['heading--is-small']]: headingLevel >= HeadingLevels.LEVEL_3,
      })}
    >
      <span className={styles.text}>{text}</span>
    </TitleTag>
  );
}
